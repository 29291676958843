import { useState, useEffect, useRef } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"

import IngestionVideo from '../assets/IngestionVideo.gif';

export default function DataIntegrationFeature({}){
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);  // Load the component
                        observer.unobserve(entry.target); // Stop observing after it's visible
                    }
                });
            },
            {
                threshold: 0.6, // Adjust this threshold as needed
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current); // Cleanup
            }
        };
    }, []);


    return (
        <Box
            ref={sectionRef}
            sx={{
                backgroundColor:'#F7F9FC',
                pr:'10vw',
                pl:'10vw',
                pt: '15vh',
            }}
        >
            <Box
                sx={{
                    display:'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    gap:'50px'
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        gap:'40px',
                        maxWidth: isSmallScreen ? '100%' : '50%',
                    }}
                >
                    <Typography fontWeight='bold' variant='h6' color='#635BFF'>
                        Data integration
                    </Typography>
                    <Typography fontWeight='bold' variant='h3'>
                        Instantly connect to machine data
                    </Typography>
                    <Typography fontSize='1.1rem'>
                        Reduce time till value and realize cost savings within the first week of installation. Praxis provides no code connectors to easily ingest data from all industry standard historians, as well as virtual tables to minimize the amount of external data storage needed to training large deep learning models.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                        maxWidth: isSmallScreen ? '100%' : '50%',
                    }}
                >
                    {
                        (isVisible || isSmallScreen) &&
                        <img width={'100%'} src={IngestionVideo} alt="Optimization Module" />
                    }
                </Box>
            </Box>
        </Box>
    )
}