
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

function Title() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fontSize = isSmallScreen ? '3.5rem' : '3.7rem'
  return (
    <Box
      sx={{
        paddingTop:'180px',
        paddingBottom:'100px',
        paddingLeft:'20px',
        paddingRight:'20px',
        backgroundColor:'#F6F5F5',
        justifyContent:'center',
        alignItems:'center',
        display:'flex',
        flexDirection:'column',
      }}
    >
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', flexWrap:'wrap'}}>
        <Typography textAlign='center' fontWeight='bold' variant='h7' fontSize={fontSize}>
            AI built for
        </Typography>
        <span
            style={{
                background: 'linear-gradient(#251566, #4625CF)',
                webkitBackgroundClip: 'text',
                webkitTextFillColor: 'transparent',
                fontSize:fontSize,
                fontWeight:'bold',
                paddingLeft:'10px',
                maxWidth:'80vw',
                wordWrap:'break-word',
            }}
        >
          manufacturing
        </span>
      </Box>
      <Typography textAlign='center' fontWeight='bold' variant='h7' fontSize='3.5rem'>
          Predict, analyze and optimize plant operations in real-time
      </Typography>
      <Typography paddingTop='30px' textAlign='center' variant='h7' fontSize='1.8rem'>
        A no code platform to build end to end deep learning solutions on sensor data
      </Typography>
    </Box>
  );
}

export default Title;
