import {useState, useEffect} from 'react';
import { Box, Typography, Grid, Button, useMediaQuery, useTheme } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import praxisLogo from '../assets/praxisLogo.png'
import Anomaly_Detection from '../assets/Anomaly_Detection.png'; // Adjust the path as necessary
// to predict, analyze and optimize plant operations in real time
export default function TitleSection ({}){
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const fontSize = isSmallScreen ? '3rem' : isMediumScreen ? '5rem' : '6rem';
    const [isHovered, setIsHovered] = useState(false); // State to track hover status

    return (
    <Box
        sx={{
            minHeight:'100vh',
            minWidth:'100vw',
            background:'white',
        }}
    >
        <Box
            sx={{
                width: '100%',
                height: isSmallScreen ? '120vh' : '100vh',
                position: 'relative',
                backgroundColor:'#FDFDFE',
                overflow: 'hidden',

                // Create the top-left triangle background with gradient
                '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                clipPath: 'polygon(0 0, 100% 0, 100% 30%, 0 80%)', // Top-left triangle
                background: `
                    linear-gradient(
                    135deg,
                    rgba(110, 224, 213, 0.8) 0%,
                    rgba(255, 165, 113, 0.8) 25%,
                    rgba(255, 95, 109, 0.8) 35%, 
                    rgba(126, 61, 255, 0.8) 60%,
                    rgba(31, 100, 255, 0.8) 85%,
                    rgba(31, 100, 255, 0.8) 100%
                    )`,
                backgroundSize: '200% 200%',
                animation: 'rotateGradient 20s linear infinite alternate-reverse', // Adjusted to linear for smooth background movement
                },

                // Blobs as pseudo-elements
                '&::after': {
                content: '""',
                position: 'absolute',
                width: '200%',
                height: '200%',
                background: 'radial-gradient(circle at 50%, rgba(255, 94, 58, 0.6), transparent 70%)',
                animation: 'moveBlobs 10s infinite alternate ease-in-out',
                },

                // Blobs for the second effect
                '&::after': {
                background: 'radial-gradient(circle at 50%, rgba(126, 61, 255, 0.6), transparent 70%)',
                animationDuration: '12s',
                },

                '@keyframes rotateGradient': {
                '0%': {
                    backgroundPosition: '0% 50%',
                },
                '100%': {
                    backgroundPosition: '100% 50%', // Gradient moves across the triangle
                },
                },

                '@keyframes moveBlobs': {
                '0%': {
                    transform: 'translate(-50%, -50%) scale(1)',
                },
                '50%': {
                    transform: 'translate(50%, 50%) scale(1.5)',
                },
                '100%': {
                    transform: 'translate(-50%, 50%) scale(1)',
                },
                },
            }}
        />
        <Box
            sx={{
                position:'absolute',
                top:0,
                width:'100%',
                // height:'100%',
            }}
        >
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'space-between',
                    pt:'10px',
                    pb:'60px',
                    pr:'10vw',
                    pl:'10vw',
                    alignItems:'center'
                }}
            >
                <img style={{maxWidth:'170px'}} src={praxisLogo} alt="logo" />
                <Button
                    sx={{
                    height:'45px',
                    mt:'10px',
                    mb:'10px',
                    pr:'20px',
                    pl:'20px',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    color:'rgba(1,1,1,1)',
                    borderRadius:'30px',
                    backgroundColor:'white',
                    textTransform:'none',
                    '&:hover':{
                        backgroundColor:'#f7f1ed',
                    },
                    gap:'10px'
                    }}
                    component="a"
                    href={`mailto:rliyanage@praxis-tech.ai`}
                    variant="text"
                    color="primary"
                    onMouseEnter={() => setIsHovered(true)} // Set hover state to true
                    onMouseLeave={() => setIsHovered(false)} // Set hover state to false
                >
                    <Typography
                        fontWeight='bold'
                        fontSize={isSmallScreen ? '0.7rem' : '1.1rem'}
                        sx={{ color: '#893C9E' }} // Set the text color to transparent
                    >Book my demo</Typography>
                    {
                        isHovered ?
                        <EastIcon sx={{fill:'#893C9E'}}/> :
                        <KeyboardArrowRightIcon sx={{fill:'#893C9E'}}/>
                    }
                </Button>
            </Box>
            <Box
                sx={{
                    display:'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    gap:'20px',
                    alignItems: isSmallScreen ? 'center' : 'none',
                }}
            >
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            gap:'10px',
                            pl:'10vw',
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                borderRadius:'20px',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                                padding:'10px',
                                pl:'20px',
                                pr:'20px',
                                width:'200px'
                            }}
                        >
                            <Typography color='white' fontWeight='bold'>
                                Backed by <span style={{color:'orange'}}>Y Combinator</span>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: isSmallScreen ? '95%' : '70%'
                            }}
                        >

                        </Box>
                        <Typography
                            variant='h1'
                            fontWeight='bold'
                            fontSize={fontSize}
                        >
                            Custom AI for manufacturing
                        </Typography>
                        <Box
                            sx={{
                                width: isSmallScreen ? '95%' : '80%'
                            }}
                        >
                            <Typography
                                variant='h7' fontSize='1.8rem'
                            >
                                We simplify the process of implementing state of the art deep learning solutions on your machine data
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            overflow: 'hidden',  // Ensure overflow is hidden
                            height: '100%',
                            width:'100%',
                            display:'flex',
                            justifyContent: isSmallScreen ? 'center' : 'none'
                        }}
                    >
                        <img style={{
                            height: isSmallScreen ? '40vh' : '70vh',      // Maintain full height of the grid item
                            objectFit: 'cover',  // Maintain aspect ratio and cover the area
                        }} src={Anomaly_Detection} alt="Praxis Platform" />
                    </Box>
            </Box>
        </Box>
    </Box>
    );
}
