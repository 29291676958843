import React, { useState, useEffect } from "react";
import {
  Box,
  Avatar,
  Typography,
  Button,
  Paper,
  Stack,
  CircularProgress,
} from "@mui/material";
import EngineeringIcon from '@mui/icons-material/Engineering';
import SmartToyIcon from "@mui/icons-material/SmartToy";
import dayjs from "dayjs";

const ChatMessage = ({ message, showButton, onScheduleReminder }) => {
  const isUser = message.sender === "user";

  return (
    <Box display="flex" justifyContent={isUser ? "flex-end" : "flex-start"} mb={2}>
      {!isUser && (
        <Box sx={{ mr: 2, display: "flex"}}>
          <SmartToyIcon fontSize="large" />
        </Box>
      )}
      <Paper
        elevation={3}
        sx={{
          padding: "10px 15px",
          backgroundColor: isUser ? "#e0f7fa" : "#f1f1f1",
          maxWidth: "60%",
        }}
      >
        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
          {message.text}
        </Typography>
        <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
          {message.timestamp}
        </Typography>

        {showButton && (
          <Box mt={2}>
            <Button variant="contained" onClick={onScheduleReminder}>
              Schedule Reminder
            </Button>
          </Box>
        )}
      </Paper>
      {isUser && (
        <Box sx={{ mr: 2, display: "flex"}}>
            <EngineeringIcon sx={{pl:'10px'}} fontSize="large" />
        </Box>
      )}
    </Box>
  );
};

const ChatMessage2 = ({ message, showButton, onScheduleReminder }) => {
    const isUser = message.sender === "user";
  
    return (
      <Box display="flex" justifyContent={isUser ? "flex-end" : "flex-start"} mb={2}>
        {!isUser && (
          <Box sx={{ mr: 2, display: "flex"}}>
            <SmartToyIcon fontSize="large" />
          </Box>
        )}
        <Paper
          elevation={3}
          sx={{
            padding: "10px 15px",
            backgroundColor: isUser ? "#e0f7fa" : "#f1f1f1",
            maxWidth: "60%",
          }}
        >
          <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
            {message.text}
          </Typography>
          <Typography variant="caption" color="textSecondary" sx={{ mt: 1 }}>
            {message.timestamp}
          </Typography>
  
          {showButton && (
            <Box mt={2}>
              <Button variant="contained" onClick={onScheduleReminder}>
                Schedule Reminder
              </Button>
            </Box>
          )}
        </Paper>
        {isUser && (
            <Box sx={{ mr: 2, display: "flex"}}>
                <EngineeringIcon sx={{pl:'10px'}} fontSize="large" />
            </Box>
        )}
      </Box>
    );
  };

const Chatbot = () => {
  const [messages, setMessages] = useState([
    {
      id: 1,
      sender: "user",
      text: "How is machine one performing today?",
      timestamp: dayjs().format("HH:mm A"),
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);
  const [idx, setIdx] = useState(0);
  const [typedText, setTypedText] = useState("");
  const botMessage = `Based on the latest sensor data analysis using anomaly detection models, machine one is operating within normal parameters. However, the forecast indicates a potential rise in motor temperature within the next 12 hours. This could be due to increased load on the system.\n\nI recommend scheduling a maintenance check and reducing operational load to avoid performance issues. Should I set a reminder for the next inspection?`;
  const [showScheduleButton, setShowScheduleButton] = useState(false);

    useEffect(() => {
        // Set up the interval to increment the count every 10 seconds
        const interval = setInterval(() => {
            setIdx((prevIdx) => {
                if (prevIdx >= botMessage.length) {
                  clearInterval(interval);  // Clear interval when max length is reached
                  setShowScheduleButton(true);
                  return prevIdx;
                }
                return prevIdx + 1;  // Otherwise, increment the index
            });
        }, 10); // 10000 milliseconds = 10 seconds

        // Clean up the interval when the component unmounts
        return () => clearInterval(interval);
    }, []); // Empty dependency array means the effect runs only once on mount

  const handleScheduleReminder = () => {
    alert("Reminder Scheduled!");
    setShowScheduleButton(false);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 600, margin: "auto", mt: 5 }}>
      <Stack spacing={3}>
        {messages.map((message, index) => (
          <ChatMessage
            key={message.id}
            message={message}
            showButton={message.id === 2 && showScheduleButton}
          />
        ))}
        <ChatMessage2
            message={{
              id: 2,
              sender: "bot",
              text: botMessage.substring(0, idx),
              timestamp: dayjs().format("HH:mm A"),
            }}
            showButton={showScheduleButton}
            idx={idx}
        />
      </Stack>
    </Box>
  );
};

export default Chatbot;
