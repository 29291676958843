import { useState } from "react"

import { Box, Typography, TextField, Button, useMediaQuery, useTheme } from "@mui/material";

export default function BootcampSection({}){
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [email, setEmail] = useState(null);

    return (
        <Box
            sx={{
                backgroundColor:'#0A2540',
                pl:'10vw',
                pr:'10vw',
                pt:'15vh',
                pb:'15vh',
                display:'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                gap:'50px'
            }}
        >
            <Box
                sx={{
                    width: isSmallScreen ? '100%' : '60%',
                    display:'flex',
                    flexDirection:'column',
                    gap:'20px',
                }}
            >
                <Typography variant='h6' fontSize='1.8rem' fontWeight='bold' color='#00D4FF'>Ready to get started?</Typography>
                <Typography variant='h6' fontSize='2.5rem' fontWeight='bold' color='white'>Book a bootcamp today!</Typography>
                <Typography variant='h6' fontSize='1rem' fontWeight='bold' color='white'>Our team will work with you to understand applicable use cases and provide an example implementation.</Typography>
                <Typography variant='h6' fontSize='1rem' fontWeight='bold' color='white'>We're so confident in our solution that we guarantee you’ll only pay if you’re completely satisfied.</Typography>
                <Typography variant='h6' fontSize='1rem' fontWeight='bold' color='white'>After signing up, a Praxis representative will be in contact within the day.</Typography>
            </Box>
            <Box
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    width: isSmallScreen ? '100%' : '40%',
                    flexDirection:'column'
                }}
            >
                <TextField
                    fullWidth
                    margin="normal"
                    label="Email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete='off'
                    variant='filled'
                    InputLabelProps={{
                        style: { color: 'white' }, // Label color
                    }}
                    InputProps={{
                        style: { color: 'white' }, // Text color
                    }}
                />
                <Button
                    sx={{
                        width:'100%',
                        backgroundColor:'rgba(255,255,255,0.2)',
                        '&:hover':{
                            backgroundColor:'rgba(255,255,255,0.15)'
                        }
                    }}
                    disabled={!email}
                    href={`mailto:rliyanage@praxis-tech.ai?subject=Bootcamp%20Sign%20Up%20Request&body=Please include any additional details.`}
                    >
                    Sign up
                </Button>
            </Box>
        </Box>
    )
}