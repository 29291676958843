import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Header from './header';
import Title from './title';
import ModelDrawer from './model-drawer';
import HistoriansDrawer from './historians-drawer';
import Features from './features';
import Footer from './footer';

import TitleSection from './sections/title';
import DataIntegrationFeature from './sections/data-integration-feature';
import ModelBuildFeature from './sections/model-build-feature';
import CoPilotFeature from './sections/copilot-feature';
import BootcampSection from './sections/bootcamp';
// import ProblemOverview from './sections/problem-overview';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Header /> */}
    <TitleSection />
    <DataIntegrationFeature />
    <ModelBuildFeature />
    <CoPilotFeature />
    <BootcampSection />
    <Footer/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
