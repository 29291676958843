import { useState, useEffect, useRef } from 'react';

import { Box, Typography, useTheme, useMediaQuery } from "@mui/material"

import Chatbot from '../components/ChatBot';


export default function CoPilotFeature({}){
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);  // Load the component
                        observer.unobserve(entry.target); // Stop observing after it's visible
                    }
                });
            },
            {
                threshold: 0.8, // Adjust this threshold as needed
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current); // Cleanup
            }
        };
    }, []);


    return (
        <Box
            ref={sectionRef}
            sx={{
                backgroundColor:'#F7F9FC',
                pr:'10vw',
                pl:'10vw',
                pt: '15vh',
                pb: '15vh',
            }}
        >
            <Box
                sx={{
                    display:'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    gap:'50px'
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        gap:'40px',
                        maxWidth: isSmallScreen ? '100%' : '50%',
                    }}
                >
                    <Typography fontWeight='bold' variant='h6' color='#635BFF'>
                        Gain insights
                    </Typography>
                    <Typography fontWeight='bold' variant='h3'>
                        Proactive problem solving with the assistance of an AI engineer
                    </Typography>
                    <Typography fontSize='1.1rem'>
                        Understand current and future conditions directly through your phone without opening a single graph. Our proprietary time series backend allows AI agents to make sense of machine data. Each instance of the agent is personalized based on an engineer's typical workflow. The entire fleet of trained models are fine tuned based on feedback given directly by end users.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display:'flex',
                        alignItems:'center',
                        width: isSmallScreen ? '100%' : '50%',
                    }}
                >
                    {
                        (isVisible || isSmallScreen) &&
                        <Chatbot/>
                    }
                </Box>
            </Box>
        </Box>
    )
}