import { useState, useEffect, useRef } from 'react';

import { Box, ListItemIcon, ListItem, ListItemText, Typography, useTheme, useMediaQuery } from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

export default function ModelBuildFeature({}){
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const LoadingSpinner = ({ isLoading, size }) => {
        const __size = size ? size : 60
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {isLoading && <CircularProgress color="primary" size={__size} />} {/* Show spinner if isLoading is true */}
          </div>
        );
    };

    const STEP_MAP = {
        'start': 0,
        'init': 0,
        'analyze': 1,
        'arch_tune': 2,
        'param_tune': 3,
        'training': 4,
        'complete': 5, 
        'success': 6,
        'failure': 6,
    };

    const [trainingStatus, setTrainingStatus] = useState(0);

    const ProgressDescription = ({stepName, step}) => {
        return (
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    gap:'5px'
                }}
            >
                <Box
                    sx={{
                    position: 'relative',
                    width: '130px',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    }}
                >
                    <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: (stepName === 'Initialize') ? '50%' : 0,
                        right: (stepName === 'Complete!') ? '50%' : 0,
                        height: '1px',
                        backgroundColor: (((STEP_MAP[step] > trainingStatus) && (trainingStatus !== 'complete')) || !trainingStatus)  ? 'lightgrey' : 'rgba(20, 174, 92, 1)',
                        transform: 'translateY(-50%)',
                    }}
                    />
                    {
                        !trainingStatus ? <CircleIcon
                            sx={{
                                fill:'lightgrey',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white', // Optional: to give a background to the icon
                                borderRadius: '50%',     // Optional: to make the background circular
                            }}
                        /> :
                        trainingStatus == 'complete' ? <CheckCircleIcon
                        sx={{
                        fill:'rgba(20, 174, 92, 1)',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white', // Optional: to give a background to the icon
                        borderRadius: '50%',     // Optional: to make the background circular
                        }}/> :
                        (STEP_MAP[step] == trainingStatus)  ?
                        <LoadingSpinner isLoading={true} size={30}/> :
                        (STEP_MAP[step] > trainingStatus) ?
                        <CircleIcon
                            sx={{
                                fill:'lightgrey',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'white', // Optional: to give a background to the icon
                                borderRadius: '50%',     // Optional: to make the background circular
                            }}
                        /> :
                        <CheckCircleIcon
                            sx={{
                            fill:'rgba(20, 174, 92, 1)',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: 'white', // Optional: to give a background to the icon
                            borderRadius: '50%',     // Optional: to make the background circular
                        }}
                        />
                    }
                </Box>
                <Typography color='rgba(30, 30, 30, 1)' fontSize='0.8rem'>
                    {stepName}
                </Typography>
            </Box>
        )
    }

    const TrainingProgress = ({}) => {
        return (
            <Box
                sx={{
                    display:'flex',
                    alignItems:'center',
                    flexWrap:'wrap'
                }}
            >   
                <ListItem sx={{paddingBottom:'30px'}}>
                    <ListItemIcon><AutoAwesomeIcon/></ListItemIcon>
                    <ListItemText>Automated training process</ListItemText>
                </ListItem>
                <ProgressDescription step={'analyze'} stepName={'Analyze Data'}/>
                <ProgressDescription step={'arch_tune'} stepName={'Select Architecture'}/>
                <ProgressDescription step={'param_tune'} stepName={'Fine-tune Parameters'}/>
                <ProgressDescription step={'training'} stepName={'Train model'}/>
                <ProgressDescription step={'complete'} stepName={'Complete!'}/>
            </Box>
        )
    }

    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);
    
    useEffect(() => {
        if(!isSmallScreen && isVisible){
            // Set up the interval to increment the count every 10 seconds
            const interval = setInterval(() => {
              setTrainingStatus(prevCount => prevCount + 1);
            }, 1000); // 10000 milliseconds = 10 seconds
        
            // Clean up the interval when the component unmounts
            return () => clearInterval(interval);
        }
        if(isSmallScreen){
            setTrainingStatus(6);
        }
    }, [isVisible, isSmallScreen]); // Empty dependency array means the effect runs only once on mount

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);  // Load the component
                        observer.unobserve(entry.target); // Stop observing after it's visible
                    }
                });
            },
            {
                threshold: 0.9, // Adjust this threshold as needed
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current); // Cleanup
            }
        };
    }, []);


    return (
        <Box
            ref={sectionRef}
            sx={{
                backgroundColor:'#F7F9FC',
                pr:'10vw',
                pl:'10vw',
                pt: '15vh',
            }}
        >
            <Box
                sx={{
                    display:'flex',
                    flexDirection: isSmallScreen ? 'column-reverse' : 'row',
                    gap:'50px'
                }}
            >
                <Box
                    sx={{
                        display:'flex',
                        alignItems:'center',
                        width: isSmallScreen ? '100%' : '50%',
                    }}
                >
                    {(isVisible || isSmallScreen) && <TrainingProgress/>}
                </Box>
                <Box
                    sx={{
                        display:'flex',
                        flexDirection:'column',
                        gap:'40px',
                        width: isSmallScreen ? '100%' : '50%',
                    }}
                >
                    <Typography fontWeight='bold' variant='h6' color='#635BFF'>
                        Model deployment
                    </Typography>
                    <Typography fontWeight='bold' variant='h3'>
                        Use no code tools to build state of the art models
                    </Typography>
                    <Typography fontSize='1.1rem'>
                        We utilize the same technology used to implement LLMs and apply it to sequential machine data.
                        Praxis provides transformer architectures custom to manufacturing environments in addition to traditional ML and statistical methods.
                        The process of determining the best architecture is automated as a part of model training.
                        Data teams can utitilze developer APIs to effeciently train and deploy models at scale.
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}