
import { Box, Typography } from "@mui/material";
import praxisLogo from './assets/praxisLogo.png'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
function Footer() {
  return (
    <Box>
      <Box
          sx={{
              background: 'linear-gradient(45deg, #FF399E, #3AAD39)',
              height:'10px'
          }}
      ></Box>
      <Box
        sx={{
          padding:'50px',
          backgroundColor:'#F6F5F5',
          justifyContent:'space-between',
          display:'flex',
          flexDirection:'row',
          gap:'30px'
        }}
      >
        <Box
          sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center'
          }}
        >
          <img style={{maxWidth:'120px', filter:'invert(100%'}} src={praxisLogo} alt="logo" />
          <Typography paddingLeft='5px' fontSize='0.85rem' color='black'>© 2024 All rights reserved</Typography>
        </Box>
        <Box
          sx={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            gap:'15px'
          }}
        >
          <Typography fontWeight='bold'>CONTACT US</Typography>
          <Box
            sx={{
              display:'flex',
              gap:'10px'
            }}
          >
            <EmailIcon/>
            <Typography>contact@praxis-tech.ai</Typography>
          </Box>
          <Box
            sx={{
              display:'flex',
              gap:'10px'
            }}
          >
            <PhoneIcon/>
            <Typography>513.969.9771</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
